<template>
  <div class="contents userInfo">
    <div class="title flexB">
      <h2>회원 정보</h2>
    </div>
    <div class="box one">
      <div class="inputBox">
        <div class="flex">
          <p>권한</p>
          <label
            ><input
              type="radio"
              name="Role"
              :value="true"
              v-model="isAdmin"
            />관리자
          </label>
          <label
            ><input
              type="radio"
              name="Role"
              :value="false"
              v-model="isAdmin"
            />일반
          </label>
        </div>
        <div class="flex">
          <p>아이디</p>
          <span>{{ accountId }}</span>
        </div>
        <div class="flex">
          <p>휴대전화</p>
          <span>{{ getMask(mobileNo) }}</span>
        </div>
        <!-- <div class="flex">
          <p>마케팅 수신 동의</p>
          <span>{{
            marketingAgreement == true
              ? "동의"
              : marketingAgreement == false
              ? "미동의"
              : ""
          }}</span>
        </div> -->
        <div class="flex">
          <p>가입일</p>
          <span>{{ moment(createdAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
        <div class="flex" v-if="dropedAt">
          <p>탈퇴일</p>
          <span>{{ moment(dropedAt).format("YYYY.MM.DD HH:mm") }}</span>
        </div>
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="submit">수정</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserDetails, updateUserDetails, removeUser } from "@/api/admin";
import { format } from "@/mixins/format";
export default {
  mixins: [format],
  name: "userDetail",

  data() {
    return {
      moment: moment,
      userId: "",
      isAdmin: false,
      username: "",
      accountId: "",
      mobileNo: "",
      createdAt: "",
      updatedAt: "",
      dropedAt: "",
      // marketingAgreement: false,
      isNodata: false,
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "1");
  },
  mounted() {
    this.userId = this.$route.query.id;
    this.getUserDetail();
  },

  methods: {
    handleClick() {
      this.total = 0;
      this.currentPage = 1;
    },
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          this.isAdmin = res.data.data.isAdmin;
          this.username = res.data.data.username;
          this.accountId = res.data.data.accountId;
          this.mobileNo = res.data.data.mobileNo;
          this.createdAt = res.data.data.createdAt;
          this.updatedAt = res.data.data.updatedAt;
          this.dropedAt = res.data.data.dropedAt;
        }
      });
    },
    submit() {
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
      };
      updateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원 정보가 수정 되었습니다.");
          this.$router.push("/admin/users");
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleWithdraw() {
      let result = confirm("해당 회원을 회원 정지 시키겠습니까?");
      if (result == false) {
        return;
      }
      let data = {
        userId: this.userId,
      };

      removeUser(data).then((res) => {
        if (res.data.status == 200) {
          alert("회원 정지가 완료 되었습니다.");
          this.$router.push("/admin/users");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
  },
};
</script>
